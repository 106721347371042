import _logger, { LOGGER_CONFIG_DEFAULT } from "../../utils/logger";
import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "@emotion/styled";
import { Global } from "@emotion/react";
import { IStylesCache } from "../../utils/styleUtils";
import { _contextKey, IWidgetLayout, WidgetAction, _widgetType, ILayoutChanges, IUpdateLayoutArgs, IWidgetProps } from "../WidgetInterface";
import { ICardRendererProps, IPageVars } from "../CardInterface";
import { CardMode, cardModeAtom } from "../../state/cardState";
import Widget from "../Widget";
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useRecoilState } from "recoil";
import { hydrateLayoutChanges, hydrateDryLayout, resolveStyleName } from "../widgetUtils";

//=====[ LOGGER ]==================================================================================

const logRender = true;
const logHookExecution = true;

const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

export const DynamicCard_widgetType = "system/DynamicCard_v1";

const CardRoot = styled.div<{ cardRootStyle?: string }>`
     width: 100%;
     height: 100%;
     overflow: auto auto;
     box-sizing: border-box;
     ${(props: any) => props.cardRootStyle || ""}
 `

const ShareCardContainer = styled.div`
     @keyframes revealShare { 0% {opacity: 0;} 100% {opacity: 1;} }
     opacity: 0;
     animation: revealShare 1s forwards;
 `

export interface IDynamicCardProps extends ICardRendererProps { };
export interface IDynamicCardWidgetLayout extends IWidgetLayout { };
export interface IDynamicCardWidgetVars extends IPageVars { };

const DynamicCard: React.FC<IDynamicCardProps> = (props) => {
    const [cardMode, setCardMode] = useRecoilState(cardModeAtom);
    const varsRef = useRef<IPageVars | undefined>();
    const hydratedVarsRef = useRef<IPageVars | undefined>();
    const layoutRef = useRef<IWidgetLayout | undefined>();
    const hydratedLayoutRef = useRef<IWidgetLayout | undefined>();
    const stylesCacheRef = useRef<IStylesCache | undefined>();
    const globalStylesCacheRef = useRef<IStylesCache | undefined>();
    const contentRef = useRef<React.ReactElement | null>(null);

    const [layoutRev, setLayoutRev] = useState<number>(0);      // Usage: setLayoutRev( rev => (rev + 1));
    const bumpLayoutRev = useCallback(() => setLayoutRev(prev => prev + 1), [props.actionDispatcher]);
    const layoutRevRef = useRef(-1);                            // Last layout revision rendered
    const renderCount = useRef(0);

    const bubbleChanges = useCallback((layout: IWidgetLayout | undefined, vars?: IPageVars) => {
        props.actionDispatcher?.({
            action: "BubbleChanges",
            layout,
            vars,
        });
    }, [props.actionDispatcher]);

    logger.debug(`### RENDER ### ${props.cardAttributes.layout._widgetType}@${props.cardAttributes.layout._contextKey}, renderCount=${renderCount.current++}, layoutRev=${layoutRevRef.current}`);

    /**
     * Keep in sync with Widget processNewLayout
     * processNewLayout is a near copy of processNewLayout in Widget, but necessary due to hook context
     */
    const processNewLayout = (updatedLayout: IWidgetLayout | undefined, updatedVars?: IPageVars, bubble: boolean = false, bumpRev: boolean = false): { changes: ILayoutChanges | undefined, content: React.ReactElement | null } => {
        if (updatedLayout || updatedVars) {
            logger.debug(`processNewLayout ${props.cardAttributes.layout._widgetType}@${props.cardAttributes.layout._contextKey}`);
            const layoutChangeArgs: IUpdateLayoutArgs = {
                prevVars: varsRef.current,
                prevHydratedVars: hydratedVarsRef.current,
                prevLayout: layoutRef.current,
                prevHydratedLayout: hydratedLayoutRef.current,
                prevStylesCache: stylesCacheRef.current,
                prevGlobalStylesCache: globalStylesCacheRef.current,
                updatedLayout,
                updatedVars,
            }
            const changes = hydrateLayoutChanges(layoutChangeArgs);

            // UPDATE THE CHANGES CACHED HERE IN THIS CONTEXT
            if (changes?.varsChanged) varsRef.current = changes.vars;
            if (changes?.hydratedVarsChanged) hydratedVarsRef.current = changes.hydratedVars;
            if (changes?.layoutChanged) layoutRef.current = changes.layout;
            if (changes?.hydratedLayoutChanged) hydratedLayoutRef.current = changes.hydratedLayout;
            if (changes?.stylesChanged) stylesCacheRef.current = changes.stylesCache;
            if (changes?.globalStylesChanged) globalStylesCacheRef.current = changes.globalStylesCache;

            if (changes) {
                // Update content based on new layout
                logger.debug(`Generate content, _widgetType=${changes.layout?._widgetType} @ _contextKey=${changes.layout?._contextKey}`);
                const content = renderContent() || null;
                contentRef.current = content;

                bubble && bubbleChanges(changes.layout, changes.vars);
                bumpRev && bumpLayoutRev();

                return { changes, content };
            } else {
                return { changes, content: contentRef.current }
            }
        }

        return { changes: undefined, content: null };
    }

    const renderContent = (): React.ReactElement | null => {
        if (!layoutRef.current) return null;

        const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = {
            _key: hydratedLayoutRef.current?._key,
            cardMode,
            vars: varsRef.current,                  // Passed down for upstream changes
            hydratedVars: hydratedVarsRef.current,
            layout: layoutRef.current,              // Passed down for upstream changes
            hydratedLayout: hydratedLayoutRef.current,
            stylesCache: stylesCacheRef.current,
            resolveStyleName,
            hydrateDryLayout,
            actionDispatcher,
        };

        let element: React.ReactElement;
        switch (cardMode) {
            case CardMode.Configure:
            case CardMode.SiteView:
            case CardMode.View:
            default:
                element = <Widget {...propsUnion} />;
                break;
        }

        return element;
    }

    useEffect(() => {
        if (props.cardAttributes.layout?._cardMode === CardMode.SiteView) {
            setCardMode(CardMode.SiteView);
        }
    }, [props.cardAttributes.layout]);

    //
    // TODO: Fix this GSAP ScrollTrigger Hack
    //
    useEffect(() => {
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 250);
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 500);
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 1000);
        setTimeout(() => {
            ScrollTrigger.refresh();
        }, 1500);
    }, []);

    // ----------------------------------------------------------------------------------------------------------------------------------------------
    // ---------- ACTION DISPATCHER
    // ----------

    const actionQueue = useRef<WidgetAction[]>([]);
    const [processActionQueue, setProcessActionQueue] = useState<{}>(); // Usage: setProcessActionQueue({});
    const actionDispatcher = useCallback((action?: WidgetAction, deferAction: boolean = false) => {

        const doAction = (action: WidgetAction) => {
            // logHookExecution && logger.debug(`[${_contextKey}] doAction ${action?.action}`);
            switch (action?.action) {
                case "BubbleChanges":
                case "UpdateLayout":
                    processNewLayout(action.layout, action.vars, true, true);
                    break

                default:
                    props.actionDispatcher?.(action);
                    break;
            }
        }

        // Push new action and then either defer or process queue now
        action && actionQueue.current.push(action);
        if (deferAction || action?.deferAction) {
            setProcessActionQueue({});
        } else {
            // Process all pending actions
            while (actionQueue.current.length > 0) {
                const action = actionQueue.current.shift();
                action && doAction(action);
            }
        }
    }, [props.actionDispatcher, setProcessActionQueue]);

    // Process pending actions
    useEffect(() => {
        actionDispatcher();
    }, [processActionQueue]);

    // ----------
    // ---------- ACTION DISPATCHER
    // ----------------------------------------------------------------------------------------------------------------------------------------------

    // ----------------------------------------------------------------------------------------------------------------------------------------------
    // ---------- RENDER
    // ----------

    // Run SSR once and then again on Client-Side
    if (layoutRev !== layoutRevRef.current) {
        logger.debug(`layoutRev changed new=${layoutRev}, prev=${layoutRevRef.current} on ${props.cardAttributes.layout._widgetType}@${props.cardAttributes.layout._contextKey}`);
        processNewLayout(props.cardAttributes.layout, props.cardAttributes.vars, false, false);
        layoutRevRef.current = layoutRev;
    }

    return (<>
        <Global styles={globalStylesCacheRef.current?.serializedObjects} />
        <CardRoot id={hydratedLayoutRef.current?._cardRootId} className={hydratedLayoutRef.current?._cardRootClassName || ""} cardRootStyle={hydratedLayoutRef.current?._cardRootStyle || ""}>
            {contentRef.current || <div style={{ color: "orange" }}>{`NO DynamicCard content`}</div>}
        </CardRoot>
    </>);

    // ----------
    // ---------- RENDER
    // ----------------------------------------------------------------------------------------------------------------------------------------------


}

export default DynamicCard;
