import _logger, { LOGGER_CONFIG_DEFAULT } from "../utils/logger";
import React, { useState, useRef, useCallback } from "react";
import styled from '@emotion/styled';
import { CardMode } from "../state/cardState";
import { IStylesCache } from "../utils/styleUtils";
import { _contextKey, IWidgetProps, _widgetType, IWidgetLayoutType, IWidgetSchema, IUpdateLayoutArgs, ILayoutChanges } from "./WidgetInterface";
import { hydrateDryLayout, resolveStyleName, newContextKey, resolveClasses, makeElementEventHandlers, makeInstanceInfo, loadWidgetSchemaSync, hydrateLayoutChanges } from "./widgetUtils";
import { IWidgetLayout } from "./WidgetInterface";
import { IPageVars } from "./CardInterface";
import { useAnimationPipeline } from "./useAnimationPipeline";
import { deepEqual } from 'fast-equals';
import { _debug } from "../utils/_debug";
import { HtmlTagWidgetType } from "./HtmlTagWidget/HtmlTagWidget";
import { SnippetWidgetType } from "./SnippetWidget/SnippetWidget";

const logHookExecution = false;

const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

const InvalidWidget = styled.div`
    background: rgba(255,0,0, 0.75);
    color: black;
    font-weight: bolder;
    text-align: center;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid black;
`

/*
    STOP RETHINKGING IF "HYDRATED LAYOUTS SHOULD BE PASSED DOWN" -- THE ANSWER IS NO
        Each Widget must call hydrate for it's specific context, exclusing child widgets.
        Hydration logic prunes recusive widgets to reduce processor load. This is required
        to support variables that change, such as during editing, or live variables
        in view mode.
*/

const Widget: React.FC<IWidgetProps<IWidgetLayout, IPageVars>> = (props) => {
    const instanceInfoRef = useRef<any>();
    const varsRef = useRef<IPageVars | undefined>();            // TODO: FIX DOESN'T RENDER IF SET TO props.vars
    const hydratedVarsRef = useRef<IPageVars | undefined>(); // TODO: FIX DOESN'T RENDER IF SET to props.hydratedVars
    const layoutRef = useRef<IWidgetLayout | undefined>();
    const hydratedLayoutRef = useRef<IWidgetLayout | undefined>();
    const stylesCacheRef = useRef<IStylesCache | undefined>();
    const globalStylesCacheRef = useRef<IStylesCache | undefined>();
    const contentRef = useRef<React.ReactElement | null>(null);
    const childWidgetElementsRef = useRef<JSX.Element[]>();
    const widgetSchemaRef = useRef<IWidgetSchema<any, any> | undefined>();
    const actionDispatcher = props.actionDispatcher;

    // TODO: Remove _contextKey requirement -- change to handle this in the setAnimationPipeline
    const { setAnimationPipeline } = useAnimationPipeline({ _id: props.hydratedLayout?._id || props.layout?._id || props.hydratedLayout?._contextKey || props.layout?._contextKey });  // A specific _id always overrides _contextKey
    // const { setAnimationPipeline } = useAnimationPipeline();  // A specific _id always overrides _contextKey

    // const [forceRenderObj, forceRender] = useState<{}>({}); // Usage: forceRender({});
    const [layoutRev, setLayoutRev] = useState<number>(0);      // Usage: setLayoutRev( rev => (rev + 1));
    const bumpLayoutRev = useCallback(() => setLayoutRev(prev => prev + 1), [props.actionDispatcher]);
    const layoutRevRef = useRef(-1);                            // Last layout revision rendered
    const renderCount = useRef(0);

    const bubbleChanges = useCallback((layout: IWidgetLayout | undefined, vars?: IPageVars) => {
        props.actionDispatcher?.({
            action: "BubbleChanges",
            layout,
            vars,
        });
    }, [props.actionDispatcher]);

    logger.debug(`### RENDER ### ${props.layout._widgetType}@${props.layout._contextKey}, renderCount=${renderCount.current++}, layoutRev=${layoutRevRef.current}`);

    /**
     * Keep in sync with DynamicCard (CARD ROOT) processNewLayout
     * processNewLayout is a near copy of processNewLayout in DynamicCard (Card Root), but necessary due to hook context
     */
     const processNewLayout = (updatedLayout: IWidgetLayout | undefined, updatedVars?: IPageVars, bubble: boolean = false, bumpRev: boolean = false): { changes: ILayoutChanges | undefined, content: React.ReactElement | null } => {
        if (updatedLayout || updatedVars) {
            logger.debug(`processNewLayout ${props.layout._widgetType}@${props.layout._contextKey}`);
            const layoutChangeArgs: IUpdateLayoutArgs = {
                prevVars: varsRef.current,
                prevHydratedVars: hydratedVarsRef.current,
                prevLayout: layoutRef.current,
                prevHydratedLayout: hydratedLayoutRef.current,
                prevStylesCache: stylesCacheRef.current,
                prevGlobalStylesCache: globalStylesCacheRef.current,
                updatedLayout,
                updatedVars,
            }
            const changes = hydrateLayoutChanges(layoutChangeArgs);

            // UPDATE THE CHANGES CACHED HERE IN THIS CONTEXT
            if (changes?.varsChanged) varsRef.current = changes.vars;
            if (changes?.hydratedVarsChanged) hydratedVarsRef.current = changes.hydratedVars;
            if (changes?.layoutChanged) layoutRef.current = changes.layout;
            if (changes?.hydratedLayoutChanged) hydratedLayoutRef.current = changes.hydratedLayout;
            if (changes?.stylesChanged) stylesCacheRef.current = changes.stylesCache;
            if (changes?.globalStylesChanged) globalStylesCacheRef.current = changes.globalStylesCache;

            if (changes) {
                instanceInfoRef.current = makeInstanceInfo({
                    _id: props._id || hydratedLayoutRef.current?._id || layoutRef.current?._id,
                    _key: props._key || hydratedLayoutRef.current?._key || layoutRef.current?._key,
                    _contextKey: props._contextKey || hydratedLayoutRef.current?._contextKey || layoutRef.current?._contextKey,
                }, props.nextKeyIndex || 0);

                // Update content based on new layout
                logger.debug(`Generate content, _widgetType=${changes.layout?._widgetType}@_contextKey=${changes.layout?._contextKey}`);
                const content = renderContent() || null;
                contentRef.current = content;

                bubble && bubbleChanges(changes.layout, changes.vars);
                bumpRev && bumpLayoutRev();

                return { changes, content };
            } else {
                return { changes, content: contentRef.current }
            }
        }
        return { changes: undefined, content: null };
    }

    /*********************************************************************************************************/
    /*************************                                                     ***************************/
    /*************************                                                     ***************************/
    /*************************   EVERYTHING BELOW SHOULD USE LAYOUT/HYLAYOUT REF   ***************************/
    /*************************                                                     ***************************/
    /*************************                                                     ***************************/
    /*********************************************************************************************************/

    const renderChildren = (done?: (children?: JSX.Element[]) => void) => {
        const contextKeysInScope: string[] = [];
        const contextKeyConflicts: string[] = [];
        const widgets: IWidgetLayoutType | undefined = hydratedLayoutRef.current?._widgets || layoutRef.current?._widgets;
        const childWidgetElements: Array<JSX.Element> = [];

        logHookExecution && logger.debug(`[${hydratedLayoutRef.current?._contextKey}] useEffect regenerate childWidgetElements`);

        // Update the layout array (update, add, remove widgets)
        let nextIndexKey = props.nextKeyIndex || 0;
        Array.isArray(widgets) && widgets.forEach((childLayout: IWidgetLayout | string, index: number) => {
            if (typeof childLayout === "object") {
                // Ensure a unique key for every element in this scope
                const keyConflict = childLayout._contextKey ? contextKeysInScope.includes(childLayout._contextKey) : false;
                keyConflict && contextKeyConflicts.push(childLayout._contextKey!);
                childLayout._contextKey && contextKeysInScope.push(childLayout._contextKey);

                // Find existing element where all properties match
                let element: JSX.Element | undefined = childWidgetElementsRef.current?.find((element: JSX.Element, index) => {
                    if (element.key !== childLayout._key) {
                        return false
                    };

                    //// TODO: CHECK NEXT LINE???

                    const layoutChanged = !deepEqual(childLayout, element.props.layout);
                    let varsChanged = !deepEqual(props.vars, element.props.vars);
                    if (element?.key !== (childLayout._key || childLayout._contextKey) || props.cardMode !== element.props.cardMode || layoutChanged || varsChanged) {
                        return false; // Something changed, re-render the element
                    } else {
                        logHookExecution && logger.debug(`[${hydratedLayoutRef.current?._contextKey}] REUSE CHILD ELEMENT: ${childLayout._contextKey}`);
                        return true;
                    }
                });

                if (!element) {
                    logHookExecution && logger.debug(`[${hydratedLayoutRef.current?._contextKey}] CREATE NEW CHILD ELEMENT: ${childLayout._contextKey}`);
                    const { _widgets, ..._parentVars }: any = layoutRef.current;
                    const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = {
                        // cardMode: props.cardMode,
                        cardMode: props.cardMode,

                        // TODO: FIX KEY THIS TO PREVENT CONSTANT RE-RENDERING!!!
                        _key: childLayout._contextKey || `${index}_${childLayout._widgetType}`,  // Best effort

                        layout: childLayout,
                        _parentVars,
                        nextKeyIndex: nextIndexKey++,
                        vars: props.vars,
                        renderChildren,
                        actionDispatcher,
                    };
                    element = <Widget {...propsUnion} key={propsUnion._key} />      // Set key = _key
                }

                contextKeyConflicts.length > 0 && logger.error(`_contxtKey conflicts within scope`, contextKeyConflicts); // TODO: Surface this error in GUI
                element && childWidgetElements.push(element);
            }
        });

        childWidgetElementsRef.current = childWidgetElements.length > 0 ? childWidgetElements : undefined;
        done?.(childWidgetElementsRef.current);
    }

    const renderContent = (): React.ReactElement | null => {

        const render = (schema?: IWidgetSchema<any, any>) => {
            if (schema?.viewModeSchema?.component) {
                switch (props.cardMode) {
                    case CardMode.Configure:
                    case CardMode.Share:
                    case CardMode.SiteView:
                    case CardMode.View:
                    default:
                        {
                            const style = hydratedLayoutRef.current?._stylizer?.style;
                            const iBoxStyle = hydratedLayoutRef.current?._stylizer?.iBoxStyle;
                            const oBoxStyle = hydratedLayoutRef.current?._stylizer?.oBoxStyle;
                            const className = resolveClasses(hydratedLayoutRef.current?._stylizer?.className, stylesCacheRef.current) || undefined; // If no className, fallback to try Widget{} style for backward compatiblity
                            const iBoxClassName = resolveClasses(hydratedLayoutRef.current?._stylizer?.iBoxClassName, stylesCacheRef.current) || undefined; // If no className, fallback to try Widget{} style for backward compatiblity
                            const oBoxClassName = resolveClasses(hydratedLayoutRef.current?._stylizer?.oBoxClassName, stylesCacheRef.current) || undefined; // If no className, fallback to try Widget{} style for backward compatiblity

                            //
                            // Render widget
                            //
                            let eventHandlers = makeElementEventHandlers(hydratedLayoutRef.current?._eventActions, actionDispatcher);
                            // eventHandlers && logger.warn(`event handlers = ${Object.keys(eventHandlers)}`);

                            const { _widgets, ..._parentVars }: any = layoutRef.current;

                            const propsUnion: IWidgetProps<IWidgetLayout, IPageVars> = {
                                cardMode: props.cardMode,
                                layout: layoutRef.current,              // Passed down for upstream changes
                                vars: varsRef.current,                  // Passed down for upstream changes
                                _parentVars,
                                stylesCache: stylesCacheRef.current,
                                hydratedLayout: hydratedLayoutRef.current,
                                hydratedVars: hydratedVarsRef.current,
                                ...(!iBoxClassName && !oBoxClassName && { _id: instanceInfoRef.current._id, _key: instanceInfoRef.current._key }),
                                ...((props._style || style) && { _style: props._style || style }),
                                ...((props._class || className) && { _class: props._class ? (props._class + " ") : "" + className }),
                                ...(!iBoxClassName && !oBoxClassName && { _eventHandlers: !oBoxClassName && eventHandlers }),
                                resolveStyleName,
                                hydrateDryLayout,
                                renderChildren,
                                actionDispatcher,
                            } as any;

                            //
                            // Create the appropriate instance of widget (view, share, etc)
                            //
                            const Widget = schema.viewModeSchema?.component;
                            const widget = Widget ? <Widget {...propsUnion} /> : null;

                            //
                            // Render iBox wrapper if defined in layout _css
                            //
                            const iBox = iBoxClassName
                                ? <div {...{
                                    className: iBoxClassName,
                                    ...(iBoxStyle && { STYLE: iBoxStyle }),
                                    ...(!oBoxClassName && { id: instanceInfoRef.current._id, key: instanceInfoRef.current._key }),
                                    ...(!oBoxClassName && eventHandlers),
                                    children: widget,
                                }} />
                                : widget;

                            //
                            // Render oBox wrapper if defined in layout _css
                            //
                            const oBox = oBoxClassName
                                ? <div {...{
                                    className: oBoxClassName,
                                    ...(oBoxStyle && { STYLE: oBoxStyle }),
                                    id: instanceInfoRef.current._id,
                                    key: instanceInfoRef.current._key,
                                    children: iBox,
                                    ...eventHandlers,
                                }} />
                                : iBox;

                            // ***********************************************************************************************
                            // ***********************************************************************************************
                            // ********************                         
                            // ******************** TODO: FIX THIS WORKAROUND!!!
                            // ********************                          
                            // ******************** The animation pipline cannot be started until respective elements from
                            // ******************** the layout file have been rendered. KICK OFF THE ANIMATION PIPELINE AS                          
                            // ******************** elements become available, or upon first complete render.                          
                            // ********************                          
                            // ***********************************************************************************************
                            // ***********************************************************************************************
                            // TODO: Associate with render event (not 1000ms)

                            const animationPipeline = hydratedLayoutRef.current?._stylizer?.pipeline || undefined;
                            animationPipeline && setTimeout(() => {
                                if (hydratedLayoutRef.current?._stylizer?.pipeline) {
                                    setAnimationPipeline(hydratedLayoutRef.current?._stylizer.pipeline, instanceInfoRef.current?._id);
                                }
                            }, 500);

                            return oBox;
                            break;
                        }
                }

            } else {
                // TODO: Append this to an card-level error list to render at bottom
                const wc = (
                    props.cardMode === CardMode.Design || (process.env.NODE_ENV === 'development')
                        ? <InvalidWidget key={newContextKey()}>INVALID WIDGET MODULE: {props.layout._widgetType} {props.layout._contextKey ? `[${props.layout._contextKey}]` : ""}</InvalidWidget>
                        : null
                );
                return wc;
            }
        }

        //
        // Render Content (main function)
        //
        (process.env.NODE_ENV === 'development' && _debug) && _debug(props.layout?._debug, logger, props.layout);

        // Ignore this widget?
        if (hydratedLayoutRef.current?._ignore
            || hydratedLayoutRef.current?._criteria?.render === false) return null;

        try {
            const _widgetType = hydratedLayoutRef.current?._widgetType;
            if (!widgetSchemaRef.current) {
                // Load new widget type
                const widgetTypeParts = _widgetType?.split("/");
                if (_widgetType && widgetTypeParts) {
                    let wType: string;
                    switch (widgetTypeParts[0]) {
                        /**
                         * SHORTHAND HANDLERS
                         */
                        case "htmltag":
                            // Handle shorthand widget types (htmltag/div, htmltag/p, etc.)
                            wType = HtmlTagWidgetType;
                            break;
                        case "snippet":
                            // Handle shorthand widget types (snippet/SNIPPET_NAME)
                            wType = SnippetWidgetType;
                            break;
                        /**
                         * ALL OTHER WIDGETS
                         */
                        default:
                            wType = _widgetType;
                            break;
                    }

                    const schema = loadWidgetSchemaSync(wType);
                    widgetSchemaRef.current = schema;
                    return render(schema);
                } else {
                    // No widgetType, clear the widgetSchema
                    widgetSchemaRef.current = undefined;
                    return render(widgetSchemaRef.current);
                }
            } else {
                return render(widgetSchemaRef.current);
            }
        } catch (e) {
            logger.error(e);
            widgetSchemaRef.current = undefined;
            return render(widgetSchemaRef.current);
        }
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------
    // ---------- RENDER
    // ----------

    // Run SSR once and then again on Client-Side
    if (layoutRev != layoutRevRef.current) {
        logger.debug(`layoutRev changed new=${layoutRev}, prev=${layoutRevRef.current} on ${props.layout._widgetType}@${props.layout._contextKey}`);
        processNewLayout(props.layout, props.vars);
        layoutRevRef.current = layoutRev;
    }

    return <>{contentRef.current}</>

    // ----------
    // ---------- RENDER
    // ----------------------------------------------------------------------------------------------------------------------------------------------
}

export default Widget;