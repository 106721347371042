import _logger, { LOGGER_CONFIG_DEFAULT } from "../utils/logger";
import React, { MutableRefObject } from "react";
import { IStylesCache, prepareCss } from "../utils/styleUtils";
import { widgetCollection } from "./widgetCollection";
import { newInstanceKey, newTimekey } from "../utils/timeKey";
import { deepEqual } from 'fast-equals';
import { hydrateVars, hydrateObject, onHydrateKeyFn } from "../utils/hydrator";
import { deepUpdateObjectKeys } from "../utils/commonUtils";
import { hydrateDryLayoutFn, ILayoutChanges, IUpdateLayoutArgs, IWidgetLayout, IWidgetProps, IWidgetSchema, IWidgetLayoutType, IWidgetEventActionsMap, WidgetActionType, WidgetActionDispatcher, WidgetAction, WidgetEventActionsType } from "./WidgetInterface";
import { IPageVars } from "./CardInterface";
import type { ISnippet, ISnippetMap } from "./SnippetWidget/SnippetWidget";
import cloneDeep from "lodash/cloneDeep";

//=====[ LOGGER ]==================================================================================

const __filename = "widgetUtils.ts";   // BROWSER ONLY - SET TO FILENAME
const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

export const newContextKey = () => newTimekey();

export const getTagAttributes = (props: IWidgetProps<IWidgetLayout, IPageVars>, extraAttributes?: any) => ({
    ...extraAttributes,                                // additionalAttribures are first to be overridden
    ...(props._id && { id: props._id }),
    ...(props._key && { key: props._key }),
    ...(props._ref && { ref: props._ref }),
    ...(props._class && { className: props._class }),
    ...(props._style && { STYLE: props._style }),
    ...(props._eventHandlers),
});

// export const getStyle = (stylesCache?: IStylesCache, styleName?: string): { name?: string, obj?: { [key: string]: any } } => {
//     if (!stylesCache || !styleName) return {};
//     const name = (stylesCache?.emotionStyles && stylesCache.emotionStyles[styleName]) || undefined;
//     const obj = (stylesCache?.serializedObjects && stylesCache.serializedObjects[styleName]) || undefined;
//     return { name, obj };
// }

export const resolveClasses = (classStr: string | undefined, styleCache?: IStylesCache): string | undefined => {
    if (!classStr) return "";
    const classes = classStr.split(" ");
    let resolved = "";
    if (styleCache) {
        classes.forEach((name) => {
            resolved += `${styleCache.emotionStyles?.[name] || name} `;
        });
    } else {
        resolved = classStr;
    }

    return resolved.trim();
}

export const resolveStyleName = (stylesCache: IStylesCache | undefined, styleName: string | undefined): string | undefined => {
    return (stylesCache?.emotionStyles?.[styleName || ""]);
}

export const parse_widgetType = (_widgetType?: string) => {
    if (!_widgetType) return "Unknown Widget Type";
    const parts = _widgetType.split("/");
    parts[0] = (parts[0] === "external") ? " (ext)" : "";
    return `${parts[1]}${parts[0]}`;
}

export const getWidgetPath = (fqName: string, relativePathToWidgets: string): string => {
    if (!fqName) return "";
    // const path: string[] = fqName.split(/[/?]/);
    const path: string[] = fqName.split("/");
    const widgetExtensions: { [key: string]: string } = {
        "widget": "_Widget",
        "system": "_Widget",
        "external": "_XWidget"
    };
    let widgetExtension = widgetExtensions[path[0]] || "_UnsupportedWidget";
    return `${relativePathToWidgets}/${path[1]}${widgetExtension}/${path[1] || "__unknown__"}${widgetExtension}`
};

export const loadWidgetSchema = async (_widgetType: string): Promise<IWidgetSchema<any, any> | undefined> => {
    // const path = getWidgetPath(`${_widgetType}`, "./system");
    const path = getWidgetPath(`${_widgetType}`, "");
    const widget = widgetCollection[_widgetType];

    // Attempt to get the widget module from the prepackaged collection(s) and if not found, lazy load with import promise
    return widget ? Promise.resolve(widget) : import(`${path}`)
        .then((module) => {
            return module?.default as IWidgetSchema<any, any>;
        })
        .catch((e) => {
            // return null;
            logger.error("WIDGET NOT FOUND", e);
            return undefined;
        });
}

export const loadWidgetSchemaSync = (_widgetType: string): IWidgetSchema<any, any> | undefined => {
    // const path = getWidgetPath(`${_widgetType}`, "./system");
    const widget = widgetCollection[_widgetType];
    return widget;
}

// export const makeInstanceInfo = (wProps: IWidgetProps<any, any>) => {
//     const _widgetType = wProps.hydratedLayout?._widgetType || wProps.layout._widgetType;
//     const _contextKey = wProps._contextKey || wProps.hydratedLayout?._contextKey || wProps.layout._contextKey;
//     const _instanceKey = newInstanceKey(_contextKey || _widgetType);
//     const _id = wProps._id || wProps.hydratedLayout?._id || wProps.layout._id || _contextKey || _instanceKey;
//     const _key = wProps._key || wProps.hydratedLayout?._key || wProps.layout._key || _contextKey || _instanceKey;
//     const instanceObj = {
//         // _instanceKey,
//         // _widgetType,
//         // _contextKey,
//         // _id,
//         // _key
//         ...(_instanceKey && { _instanceKey }),
//         ...(_widgetType && { _widgetType }),
//         ...(_contextKey && { _contextKey }),
//         ...(_id && { _id }),
//         ...(_key && { _key }),
//     };
//     return (instanceObj);
// }

export interface IMakeInstanceInfo {
    _id?: string;
    _contextKey?: string;
    _key?: string;
    hydratedLayout?: IWidgetLayout,
    layout?: IWidgetLayout,
}

export const makeInstanceInfo = (instanceInfo: IMakeInstanceInfo, nextIndex: number) => {
    const _widgetType = instanceInfo.hydratedLayout?._widgetType || instanceInfo.layout?._widgetType;
    const _contextKey = instanceInfo._contextKey || instanceInfo.hydratedLayout?._contextKey || instanceInfo.layout?._contextKey;

    // const _instanceKey =  newInstanceKey(_contextKey || _widgetType);
    const _instanceKey = newInstanceKey(_contextKey || "autokey", "_", nextIndex);        // FIX ID GENERATION SERVER / CLIENT

    const _id = instanceInfo._id || instanceInfo.hydratedLayout?._id || instanceInfo.layout?._id || _contextKey || _instanceKey;
    const _key = instanceInfo._key || instanceInfo.hydratedLayout?._key || instanceInfo.layout?._key || _contextKey || _instanceKey;
    const instanceObj = {
        // _instanceKey,
        // _widgetType,
        // _contextKey,
        // _id,
        // _key
        ...(_instanceKey && { _instanceKey }),
        ...(_widgetType && { _widgetType }),
        ...(_contextKey && { _contextKey }),
        ...(_id && { _id }),
        ...(_key && { _key }),
    };
    return (instanceObj);
}

/**
 * 
 * @param snippetName 
 * @returns 
 */
export const loadSnippet = (snippetName: string, snippetMap: ISnippetMap | undefined): ISnippet | undefined => {
    if (!snippetName || !snippetMap) return undefined;
    let snippet: ISnippet | undefined;

    // TODO: Add support for @username prefix
    snippet = snippetMap?.[snippetName];
    const seededSnippet = seedSnippetKeys(snippet);
    return seededSnippet;
};

export function seedSnippetKeys(snippet: ISnippet | undefined): ISnippet | undefined {
    if (!snippet) return snippet;

    const layout = seedLayoutKeys(snippet.layout) as IWidgetLayout;
    return ({ ...snippet, layout });
}

export function seedSnippetMapKeys(snippetMap: ISnippetMap | undefined): ISnippetMap | undefined {
    if (!snippetMap) return snippetMap;

    const seededSnippetMap: ISnippetMap = {};
    const keys = Object.keys(snippetMap);
    keys.forEach((key, index) => {
        const layout = seedLayoutKeys(snippetMap[key].layout) as IWidgetLayout;
        seededSnippetMap[key] = {
            ...snippetMap[key],
            layout
        }
    });

    return seededSnippetMap;
}

export function seedLayoutKeys(layout: IWidgetLayout | undefined, usedKeys: string[] = []): IWidgetLayout | undefined {

    let keyCounter = 1000;

    // TODO: Seed Snippet with Keys

    if (!layout) return layout;
    const { _widgets, ...shallowLayout }: { _widgets: IWidgetLayoutType, shallowLayout: IWidgetLayout } = layout as any;
    const seededLayout: IWidgetLayout = shallowLayout as any;

    seededLayout._key = seededLayout._key || seededLayout._contextKey || newInstanceKey(seededLayout._contextKey, undefined, keyCounter++);
    // seededLayout._key = seededLayout._key || seededLayout._contextKey || newInstanceKey(seededLayout._contextKey);
    if (usedKeys.includes(seededLayout._key)) {
        const replacementKey = newInstanceKey(seededLayout._contextKey || seededLayout._widgetType, undefined, keyCounter++);
        logger.error(`duplicate key: ${seededLayout._key}. REPLACED WITH: ${replacementKey}`);
        seededLayout._key = replacementKey;
    }

    if (typeof _widgets === "object") {
        // seededLayout._widgets = new Array<IWidgetLayout | string>();
        seededLayout._widgets = new Array<IWidgetLayout>();
        const _usedKeys: string[] = new Array<string>();

        // if (_widgets.length > 1) {   // If there are more than one widgets, then we need unqiue keys to ensure proper rendering updates
        _widgets.forEach((widget) => {
            if (typeof widget === "object") {
                const iWidget = seedLayoutKeys(widget, _usedKeys);   // Handle both wigets and non-widget (comment string entries)
                if (iWidget) {
                    (seededLayout._widgets as Array<IWidgetLayout>).push(iWidget);
                    iWidget._key && _usedKeys.push(iWidget._key)
                }
            } else {
                (seededLayout._widgets as Array<IWidgetLayout>).push(widget);
            }
        })
        // }
    } else {
        if (_widgets) {
            seededLayout._widgets = _widgets;
        } else {
            delete seededLayout._widgets;
        }
    }
    return seededLayout;
}

// export const hydrateLayoutVars: hydrateLayoutFn = <T extends unknown>(vars?: T): T | undefined => hydrateVars(vars as any) as T;

export interface IHydrateLayoutOptions {
    hydrateWidgets?: boolean;
    lateBindingHydration?: boolean;
    varsUsed?: {  // Undefined is ignored. Object passed will be populated with variables used to hydrate
        [varPath: string]: any;
    }
    onVarUsed?: (varName: string, prevValue?: any, varsUsed?: any) => any;
}

/**
 * Hydrate (dry) widget layout.
 * THIS DOES NOT HYDRATE CHILD WIDGETS because each subsequent widget must hydrate it self.
 * 
 */
// export const hydrateLayout: hydrateLayoutFn = <T extends unknown>(layout?: T, hydratedVars?: IPageVars,
//     hydrateWidgets: boolean = false, lateBindingHydration: boolean = false): T | undefined => {
export const hydrateDryLayout: hydrateDryLayoutFn = <T extends unknown>(layout?: T, hydratedVars?: IPageVars,
    options?: IHydrateLayoutOptions): T | undefined => {

    if (!layout || !hydratedVars) return layout; // If already hydrated or no vars return layout

    const _options = {
        // Defaults
        hydrateWidgets: false,
        lateBindingHydration: false,
        ...options                      // Overwrite with passed values
    }

    let hydrated: T;
    const varsUsed = {};
    // const onVarUsed = (varName: string, prevValue?: any, varsUsed?: any) => {
    //     return varName;
    // }

    if (_options.hydrateWidgets) {
        // ONLY USE DEEP FOR VARS!
        hydrated = hydrateObject(layout as any, hydratedVars, undefined, _options) as T;
    } else {
        // Hydrate _widgets if it's a string for hydration
        if (typeof (layout as any)._widgets === "string") {
            const onHydrateKey: onHydrateKeyFn = (key) => {
                return key !== "_widgets";
            }
            hydrated = hydrateObject(layout as any, hydratedVars, undefined, { ..._options, onHydrateKey }) as T;
        } else {
            // DO NOT Hydrate _widgets
            const { _widgets, ...shallowLayout } = layout as any;   // Remove (child) widgets
            hydrated = hydrateObject(shallowLayout, hydratedVars, undefined, _options) as T;
            if (_widgets) {
                (hydrated as any)._widgets = _widgets; // Re-inject dry widgets (not hydrated)
            } else {
                delete (hydrated as any)._widgets;
            };
        }
    }

    // TODO: Fill in @assets signed urls from proxy map

    return hydrated;
}

/**
 * 
 * @param ref Mutable reference
 * @param updatedValue updated value
 * @param onChange call function if changed
 * @returns 
 */
export const updateRefX = (ref: MutableRefObject<any>, updatedValue: any, pure: boolean = true, onChange?: (ref: MutableRefObject<any>) => void): boolean => {
    let changed: boolean = false;

    if (typeof updatedValue === "object") {
        // If no ref object or not an object, create a ref object
        if (!ref.current || typeof ref.current !== "object") {
            ref.current = {};
            changed = true;
        }

        // If the ref object changed, update it
        try {
            if (!deepEqual(ref.current, updatedValue)) {
                const { changed: objChanged, updatedObj } = deepUpdateObjectKeys(ref.current, updatedValue, pure);   // Always create a new pure target object
                ref.current = updatedObj;
                changed = objChanged;
            }
        } catch (err) {
            const { changed: objChanged, updatedObj } = deepUpdateObjectKeys(ref.current, updatedValue, pure);   // Always create a new pure target object
            ref.current = updatedObj;
            changed = objChanged;
        }
    } else if (updatedValue !== ref.current) {
        ref.current = updatedValue;
        changed = true;
    }

    changed && onChange?.(ref);
    return changed;
}

/**
 * 
 * @param prev 
 * @param updated 
 * @param pure If updatedValue is different and pure is true, clone the updatedValue
 * @returns 
 */
export const getWorkingObj = (prev: any, updated: any, pure: boolean): { changed: boolean, value: any } => {
    let changed = false;
    let value = prev;

    if (updated === undefined) return prev;

    if (typeof updated === "object") {
        // Is an object
        value = (typeof prev === "object") ? value : {};   // Convert value to object if it isn't
        try {
            // TODO: Is a top-level __REV__ flag a good course of action?
            // if( !value.___REV__ || value.___REV__ !== updatedValue.__REV__ ) {                
            // }
            if (!deepEqual(value, updated)) {
                const { changed: objChanged, updatedObj } = deepUpdateObjectKeys(value, updated, pure);   // Always create a new pure target object
                value = updatedObj;
                changed = objChanged;
            } else {
                value = pure ? cloneDeep(value) : value;
            }
        } catch (err) {
            const { changed: objChanged, updatedObj } = deepUpdateObjectKeys(value, updated, pure);   // Always create a new pure target object
            value = updatedObj;
            changed = objChanged;
        }
    } else if (prev !== updated) {
        // Not an object, but changed
        value = updated;
        changed = true;
    }

    return {
        changed,
        value
    };
}

/**
 * updateLayout updates the references for the layout and layout vars objects. This second-gen
 * merges local variables from the layout into/over the global vars which results in a "scope"
 * concept just like Javascript and other languages. The main reason for this is to enable card
 * templates to be used and specific fields to be updated by the end-user of the template.
 * 
 * @param IUpdateLayoutArgs 
 * @returns changes structure or undefined if no changes
 */
export const hydrateLayoutChanges = (updateArgs: IUpdateLayoutArgs): ILayoutChanges | undefined => {
    // TODO: Benchmark if better to walk and compare or simply update

    const {
        prevVars,
        prevHydratedVars,
        prevLayout,
        prevHydratedLayout,
        prevStylesCache,
        prevGlobalStylesCache,
        // UPDATED FIELDS
        updatedLayout,
        updatedVars
    } = updateArgs;

    //
    // It may be faster to simply hydrate rather than to compare full/deep structures first.
    // More time needs to be done to benchmark this, and to have push-style hydration.
    // If each widget monitored its own changes better, this can be optimized.
    //

    // VARS
    // const gotUpdateVars = "updatedVars" in updateArgs;
    const gotUpdateVars = !!updatedVars;
    let varsChanged = false;
    let hydratedVarsChanged = false;
    let newHydratedVars: IPageVars | undefined = prevHydratedVars;
    if (gotUpdateVars) {
        const workingVars = getWorkingObj(prevVars, updatedVars, true); // Return cloned obj, if changed
        varsChanged = workingVars.changed;
        if (varsChanged) {
            const tempHyVars = hydrateVars(workingVars.value);
            const workingHyVars = getWorkingObj(prevHydratedVars, tempHyVars, false); // Return cloned obj, if changed
            hydratedVarsChanged = workingHyVars.changed;
            if (hydratedVarsChanged) {
                newHydratedVars = workingHyVars.value;
            }
        }
    }

    // LAYOUT
    // const gotUpdateLayout = "updatedLayout" in updateArgs;
    const gotUpdateLayout = !!updatedLayout;
    let layoutChanged = false;
    let hydratedLayoutChanged = false;
    let newHydratedLayout: IWidgetLayout | undefined = prevHydratedLayout;
    if (gotUpdateLayout || hydratedVarsChanged) {
        const workingLayout = getWorkingObj(prevLayout, updatedLayout, true); // Return cloned obj, if changed
        layoutChanged = workingLayout.changed;
        if (layoutChanged) {
            const tempHyLayout = hydrateDryLayout(workingLayout.value, newHydratedVars);
            const workingHyLayout = getWorkingObj(prevHydratedLayout, tempHyLayout, false); // Return cloned obj, if changed
            hydratedLayoutChanged = workingHyLayout.changed;
            if (hydratedLayoutChanged) {
                newHydratedLayout = workingHyLayout.value;
            }
        }
    }

    // CSS/ STYLES
    let stylesChanged = false;
    const cssChanged = prevLayout?._stylizer?.css !== updatedLayout?._stylizer?.css;
    const hydratedCssChanged = prevHydratedLayout?._stylizer?.css !== newHydratedLayout?._stylizer?.css;
    let stylesCache: IStylesCache | undefined = prevStylesCache;
    if (hydratedCssChanged) {
        stylesCache = newHydratedLayout?._stylizer?.css ? prepareCss(newHydratedLayout?._stylizer?.css) : undefined;
        stylesChanged = true;
    }

    // CSS/ STYLES
    let globalStylesChanged = false;
    const globalCssChanged = prevLayout?._globalCss !== updatedLayout?._globalCss;
    const globalHydratedCssChanged = prevHydratedLayout?._globalCss !== newHydratedLayout?._globalCss;
    let globalStylesCache: IStylesCache | undefined = prevGlobalStylesCache;
    if (globalHydratedCssChanged) {
        globalStylesCache = newHydratedLayout?._globalCss ? prepareCss(newHydratedLayout?._globalCss) : undefined;
        globalStylesChanged = true;
    }

    if (varsChanged || hydratedVarsChanged
        || layoutChanged || hydratedLayoutChanged
        || stylesChanged || globalStylesChanged
    ) {
        const changes: ILayoutChanges = {
            // VARS
            varsChanged,
            vars: updatedVars,
            hydratedVarsChanged,
            hydratedVars: newHydratedVars,
            // LAYOUT
            layoutChanged,
            layout: updatedLayout,
            hydratedLayoutChanged,
            hydratedLayout: newHydratedLayout,
            // STYLES
            stylesChanged,
            stylesCache,
            globalStylesChanged,
            globalStylesCache,
        }
        return changes;
    } else {
        return undefined;
    }
}

/**
 * SEE LIST OF ELEMENT EVENTS
 * https://developer.mozilla.org/en-US/docs/Web/API/Element#events
 * https://developer.mozilla.org/en-US/docs/Web/Events
 * 
 * React Synthetic Events (names)
 * https://reactjs.org/docs/handling-events.html
 * https://reactjs.org/docs/events.html#mouse-events
 * 
 * @param eventActions 
 * @param actionDispatcher 
 * @returns 
 */
export const makeElementEventHandlers = (eventActions?: WidgetEventActionsType, actionDispatcher?: WidgetActionDispatcher) => {

    if (!eventActions || typeof eventActions !== "object") return undefined;

    const eventActionProps: any = {};

    Object.entries(eventActions || {}).forEach((eventActionArr) => {
        const prop = eventActionArr[0];
        const action: WidgetAction = eventActionArr[1];

        switch (action?.action) {
            case "Alert":
                eventActionProps[prop] = (e: React.SyntheticEvent) => {
                    action.preventDefault && e?.preventDefault?.();
                    alert(action.message);
                };
                break;

            case "Navigate":
                eventActionProps[prop] = actionDispatcher ? (e: React.SyntheticEvent) => {
                    action.preventDefault && e?.preventDefault?.();
                    actionDispatcher(action);
                } : undefined;
                break;

            // case "NavigateToPage":
            //     listenerProps[prop] = actionDispatcher ? (e: React.SyntheticEvent) => {
            //         action.preventDefault && e?.preventDefault?.();
            //         actionDispatcher(action);
            //     } : undefined;
            //     break;
        }
    });

    return ({ ...eventActionProps });    // Cleanup undefined entries
}