import type { Dispatch, EventHandler, HTMLAttributes, LazyExoticComponent, SyntheticEvent } from "react";
import type { IPageVars } from "./CardInterface";
import type { CardMode } from "../state/cardState";
import type { IStylesCache } from "../utils/styleUtils";
import type { WAnimpipeline } from "./useAnimationPipeline";
import type { FieldFactoryField, FieldFactoryFieldType } from "../components/FieldFactory/FieldFactoryInterface";
import type { IHydrateLayoutOptions } from "./widgetUtils";
import type { _debugAction } from "../utils/_debug";
// import type { IDocSet } from "../components/CodeEditor/CodeEditorTypes";

export const DEFAULT_PAGE_SCROLLER_ID = "main_scroller";
export const DEFAULT_PAGE_SCROLLER_ID_STR = `#${DEFAULT_PAGE_SCROLLER_ID}`;

export const DEFAULT_PAGE_CONTENT_ID = "main_content";
export const DEFAULT_PAGE_CONTENT_ID_STR = `#${DEFAULT_PAGE_CONTENT_ID}`;

export enum WidgetLockState {
    None = "",
    NotAuthorized = "NOT_AUTHORIZED",
    ProFeature = "PRO_FEATURE",
}

export enum WidgetMode {
    Hidden,
    Edit,
    OwnerView,
    Share,
    View,
    // Sending,
    // Blurred,
}

export type _widgetType = string;
export type _contextKey = string;

export interface IWidgetRendererSchema<T_PROPS> {
    rendersChildren?: boolean;              // Renders children
    rendersTagAttributes?: boolean;         // Renders tag attributes such as class and listeners
    component?: LazyExoticComponent<React.FC<T_PROPS>> | React.FC<T_PROPS>; // USE LAZY FOR LARGE COMPONENTS
    field?: FieldFactoryField;
    // fields?: string[];
    fieldMap?: {
        [key: string]: {
            // fieldKey: string,
            fieldType: FieldFactoryFieldType;
            label: string,
            varPath: string;  // "^[~message]", // context: "^[~message]" local: "^[.message]" global: "^[message]" 
            validate?: RegExp | ((value: any) => Error | undefined),
            customFieldComponent?: React.FC<any>;
        }
    }
}

export interface IWidgetFeatures { }

export interface IWidgetSchema<T_WIDGETTYPE, T_PROPS> {
    widgetType: T_WIDGETTYPE;
    widgetFeatures?: IWidgetFeatures;
    viewModeSchema?: IWidgetRendererSchema<T_PROPS>;
    shareModeSchema?: IWidgetRendererSchema<T_PROPS>;
    designModeSchema?: IWidgetRendererSchema<T_PROPS>;
    // component?: React.FC<T_PROPS> | LazyExoticComponent<React.FC<T_PROPS>>;  // TODO: DEPROCATE THIS FIELD <-------------------------------------------------- TODO
    // newWidget: (props: T) => { component: React.ReactNode, _contextKey: _contextKey };
}

export interface IContextVars {
    _contextKey?: _contextKey;
    // [key: string]: any;
};

export type IContextVarsMap = {
    [key: string]: IContextVars;
}

export interface IWidgetStylizer {
    // node: React.ReactNode;
    css?: string;
    style?: string;                         // Defined attributes has presidence over classname
    className?: string;
    iBoxStyle?: string;                     // Defined attributes has presidence over classname
    iBoxClassName?: string;
    oBoxStyle?: string;                     // Defined attributes has presidence over classname 
    oBoxClassName?: string;
    pipeline?: WAnimpipeline;
}

export interface IWatchVar {
    varPath: string;
    fqVarPath?: string;
    operation?: "nop" | "render";
    onChange?: (value: any) => void;
}

export interface IWidgetEventActionsMap {
    [eventType: string]: WidgetAction;   // Example: "onClick": {action: xxxx, xxx: yyyy}
}

export type WidgetEventActionsType = string | IWidgetEventActionsMap;

export interface IWidgetEventHandlers {
    [eventType: string]: EventHandler<SyntheticEvent<any>>;
}

export interface IWidgetLayout {
    _ignore?: boolean;                      // Ignores this widget
    //
    _widgetType?: _widgetType;
    _id?: string;
    _key?: string;
    _contextKey?: _contextKey;
    _stylizer?: IWidgetStylizer;
    _widgets?: IWidgetLayoutType;
    _eventActions?: WidgetEventActionsType; // Include string for hydration
    _criteria?: IWidgetCritera;             // CONDITIONS
    // [key: string]: any;                     // SUPPORT ALL VARIABLES FOR ALL WIDGETS
    //
    // Card Root Support (only processed at the root level)
    //
    _globalCss?: string;
    _cardRootId?: string;
    _cardRootStyle?: string;
    _cardRootClassName?: string;
    // _customCardRoot?: boolean;              // Set to true if other main_scroller, etc.
    //
    // _watchVars?: IWatchVar[]
    //
    // DEBUG
    //
    _debug?: _debugAction;                  // DEBUGGING
}

export type IWidgetLayoutType = IWidgetLayout[] | string;

export interface IWidgetLayoutTuple<TWidgetLayout, TContextVars> {
    layout: IWidgetLayout & TWidgetLayout;              // Layout to use when inserting a new instance into the layout (or resetting to defaults)
    vars?: IPageVars & TContextVars;     // Context vars to use when inserting a new instance into the layout (or resetting to defaults)
}

export type WidgetActionDispatcher = Dispatch<WidgetAction>;

export interface IWidgetInstanceAttributes {
    _widgetType: _widgetType;
    _contextKey?: _contextKey;
    instanceKey: string;
    actionDispatcher: WidgetActionDispatcher;
    // serializeLayout: () => IWidgetTuple | undefined;
    // friendlyName: string;
}

export type WidgetDidMountFn = (widgetAttributes?: IWidgetInstanceAttributes) => void;
export type WidgetDidLoadFn = () => void;
export type UpdateLayoutfn = (updatedWidgetLayout?: IWidgetLayout, updatedWidgetVars?: IPageVars) => void;
export type resolveStyleNameFn = (stylesCache: IStylesCache | undefined, styleName: string | undefined) => string | undefined;
// export type getStylesCacheFn = (stylesCache?: IStylesCache, styleName?: string) => { name?: string, obj?: { [key: string]: any } };
export type hydrateDryLayoutFn = <T extends unknown>(layout?: T, hydratedVars?: IPageVars, options?: IHydrateLayoutOptions) => T | undefined;
// export type hydrateLayoutFn = <T extends unknown>(layout?: T, hydratedVars?: IPageVars, options?: IHydrateOptions) => T | undefined;
export type renderChildrenFn = (done?: (elements?: JSX.Element[]) => void) => void;

export interface IUpdateLayoutArgs {
    // VARS
    prevVars?: IPageVars | undefined,
    prevHydratedVars?: IPageVars | undefined,
    // LAYOUT
    prevLayout?: IWidgetLayout | undefined,
    prevHydratedLayout?: IWidgetLayout | undefined,
    // CSS / STYLES
    prevStylesCache?: IStylesCache;
    prevGlobalStylesCache?: IStylesCache;
    //
    // UPDATES
    //
    updatedLayout?: IWidgetLayout,
    updatedVars?: IPageVars,
    /**
     * css and _glboalCss are AUTOMATICALLY EXTRACTED FROM
     * LAYOUT TO PRESERVE SINGLE-SOURCE OF TRUTH
     */
}

export type ILayoutChanges = undefined | {
    // VARS
    varsChanged?: boolean;
    vars?: IPageVars;
    hydratedVarsChanged?: boolean;
    hydratedVars?: IPageVars;
    // LAYOUT
    layoutChanged?: boolean;
    layout?: IWidgetLayout;
    hydratedLayoutChanged?: boolean;
    hydratedLayout?: IWidgetLayout;
    // STYLES
    stylesChanged?: boolean;
    stylesCache?: IStylesCache;
    globalStylesChanged?: boolean;
    globalStylesCache?: IStylesCache;
}

export interface IWidgetProps<TWidgetLayout, TWidgetVars> {
    // node?: HTMLElement;
    _id?: string;
    _key?: string;
    _contextKey?: _contextKey;
    _ref?: React.LegacyRef<HTMLDivElement>;
    _class?: string;
    _style?: string;
    // _eventActions?: IWidgetEventActions;
    _eventHandlers?: IWidgetEventHandlers;              // These are actual event handlers to be spread into the element
    _parentVars?: { [key: string]: any };
    cardMode?: CardMode;
    vars?: IPageVars & TWidgetVars;                     // Only present if layout is dry
    hydratedVars?: IPageVars & TWidgetVars;             // Only present if layout is dry
    layout: IWidgetLayout & TWidgetLayout;              // Layout is is dry or hydrated (if dry, vars and hydrate function is passed)
    hydratedLayout?: IWidgetLayout & TWidgetLayout;     // Layout is is dry or hydrated (if dry, vars and hydrate function is passed)
    stylesCache?: IStylesCache;
    globalStylesCache?: IStylesCache;


    nextKeyIndex?: number;
    
    
    hydrateDryLayout?: hydrateDryLayoutFn;                    // Only present if layout is dry
    renderChildren?: renderChildrenFn;
    resolveStyleName?: resolveStyleNameFn;
    actionDispatcher?: WidgetActionDispatcher;
    // didMount?: WidgetDidMountFn;
    didLoad?: WidgetDidLoadFn;
}


//
// -------------- CRITERIA - TODO: MOVE TO OWN FILE ------------------------------------------
//
export interface ICriteria {
    hidden?: string;
    startDate?: string;
    endDate?: string;
    regions?: string;
}

export interface IWidgetCritera extends ICriteria {
    _ignore?: boolean;         // Duplicate of root level _ignore
    render?: boolean;          // Default = true (undefined same as true)
}

//
// -------------- ACTIONS ------------------------------------------
//
export type WidgetActionType =
    // --- ACTIONS AVAILABLE FROM LAYOUT ---
    "Alert" |
    "Navigate" |
    "SetTopNav" |
    "Timer" |
    "Interval" |
    // --- INTERNAL ACTIONS [TODO: FILTER FROM LAYOUT ACCESS] ---
    "Refresh" |
    "SetBackground" |
    "SetOverlay" |
    "EditWidget" |
    "PageClick" |
    "BubbleChanges" |
    "UpdateLayout" |
    "UpdateVars" |
    // --- EDITOR ---
    "SetWidgetEditor" |
    "OpenWidgetEditor" |
    "CloseWidgetEditor" |
    "SaveEdits" |
    "CancelEdits" |
    "AppendWidget" |
    "DeleteWidget" |
    "DoSnapshot" |
    "Undo" |
    "Redo" |
    "OpenWidgetBrowser" |
    "CloseWidgetBrowser" |
    "OpenLayoutCodeEditor" |
    "OpenWidgetCssCodeEditor" |
    "CodeEditorSelectDocument" |
    "CloseCodeEditor" |
    "CancelCodeEdits" |
    "SaveCodeEdits" |
    // --- DEBUG ---
    "Debug";

// export enum WidgetActionType {
//     // ACTIONS AVAILABLE FROM LAYOUT
//     Alert = "Alert",
//     Navigate = "Navigate",
//     // NavigateToPage = "NavigateToPage",
//     SetTopNav = "SetTopNav",
//     Timer = "Timer",
//     Interval = "Interval",
//     // INTERNAL ACTIONS [TODO: FILTER FROM LAYOUT ACCESS]
//     Refresh = "Refresh",
//     SetBackground = "SetBackground",
//     SetOverlay = "SetOverlay",
//     EditWidget = "EditWidget",
//     PageClick = "PageClick",
//     BubbleChanges = "BubbleChanges",
//     UpdateLayout = "UpdateLayout",
//     UpdateVars = "UpdateVars",
//     SetWidgetEditor = "SetWidgetEditor",
//     // OpenWidgetEditor = "OpenWidgetEditor",
//     // CloseWidgetEditor = "CloseWidgetEditor",
//     SaveEdits = "SaveEdits",
//     CancelEdits = "CancelEdits",
//     AppendWidget = "AppendWidget",
//     DeleteWidget = "DeleteWidget",
//     DoSnapshot = "DoSnapshot",
//     Undo = "Undo",
//     Redo = "Redo",
//     OpenWidgetBrowser = "OpenWidgetBrowser",
//     CloseWidgetBrowser = "CloseWidgetBrowser",
//     OpenLayoutCodeEditor = "OpenLayoutCodeEditor",
//     OpenWidgetCssCodeEditor = "OpenWidgetCssCodeEditor",
//     CodeEditorSelectDocument = "CodeEditorSelectDocument",
//     CloseCodeEditor = "CloseCodeEditor",
//     CancelCodeEdits = "CancelCodeEdits",
//     SaveCodeEdits = "SaveCodeEdits",
//     // DEBUG
//     Debug = "Debug",
// }

export interface IWdigetActionBase {
    action: WidgetActionType;
    timekey?: string;
    deferAction?: boolean;
    preventDefault?: boolean; // Used for event listeners
}

export interface IWidgetActionAlert extends IWdigetActionBase {
    action: "Alert";
    message?: any;
}

/**
 * TARGET
 * 
 * REF: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target
 * REF: https://stackoverflow.com/questions/18470097/difference-between-self-top-and-parent-in-the-anchor-tag-target-attribute
 * REF IMAGE: https://i.stack.imgur.com/VjEBE.png
 * 
 * _embed opens content in modal dialog
 * 
 */
export interface IWidgetActionNavigate extends IWdigetActionBase {
    action: "Navigate";
    url: string;
    // target?: "_self" | "_blank" | "_parent" | "_top" | "_embed",    // _self, _blank, _parent, and _top, "_embed"
    target?: "_self" | "_blank" | "_parent" | "_top" | "_embed",    // _self, _blank, _parent, and _top, "_embed"
    features?: string
}

// export interface IWidgetActionNavigateToPage extends IWdigetActionBase {
//     action: "NavigateToPage";
//     pagePath: string;
// }

export interface IWidgetActionSetTopNav extends IWdigetActionBase {
    action: "SetTopNav";
    message?: any;
}

export interface IWidgetActionTimer extends IWdigetActionBase {
    action: "Timer";
    delay: number;
    timerAction: WidgetAction;
}

export interface IWidgetActionInterval extends IWdigetActionBase {
    action: "Interval";
    delay: number;
    timerAction: WidgetAction;
}


//

export interface IWidgetActionEditWidget extends IWdigetActionBase {
    action: "EditWidget";
    key?: string;
}

export interface IWidgetActionPageClick extends IWdigetActionBase {
    action: "PageClick";
}

export interface IWidgetActionUpdateLayout extends IWdigetActionBase {
    action: "UpdateLayout";
    layout?: IWidgetLayout;
    vars?: IPageVars;
}

export interface IWidgetActionBubbleChanges extends IWdigetActionBase {
    action: "BubbleChanges";
    layout?: IWidgetLayout;
    vars?: IPageVars;
    // contextVarsMapCollection?: IContextVarsMap;
}

export interface IWidgetActionRefresh extends IWdigetActionBase {
    action: "Refresh";
}

export interface IWidgetActionSetBackground extends IWdigetActionBase {
    action: "SetBackground";
    content?: React.ReactElement | null;
}

export interface IWidgetActionSetOverlay extends IWdigetActionBase {
    action: "SetOverlay";
    content?: React.ReactElement;
}

// export interface IWidgetActionSetWidgetEditor extends IWdigetActionBase {
//     action: "SetWidgetEditor";
//     editor?: IWidgetEditorInstance;
// }

// export interface IWidgetActionOpenWidgetEditor extends IWdigetActionBase {
//     action: "OpenWidgetEditor";
//     editor: IWidgetEditorInstance;
// }

// export interface IWidgetActionCloseWidgetEditor extends IWdigetActionBase {
//     action: "CloseWidgetEditor";
//     closingEditor?: IWidgetEditorInstance;
// }

export interface IWidgetActionOpenWidgetBrowser extends IWdigetActionBase {
    action: "OpenWidgetBrowser";
}

export interface IWidgetActionCloseWidgetBrowser extends IWdigetActionBase {
    action: "CloseWidgetBrowser";
}

export interface IWidgetActionOpenLayoutCodeEditor extends IWdigetActionBase {
    action: "OpenLayoutCodeEditor";
}

export interface IWidgetActionOpenWidgetCssCodeEditor extends IWdigetActionBase {
    action: "OpenWidgetCssCodeEditor";
}

export interface IWidgetActionCloseCodeEditor extends IWdigetActionBase {
    action: "CloseCodeEditor";
}

// export interface IWidgetActionSaveCodeEdits extends IWdigetActionBase {
//     action: "SaveCodeEdits";
//     docSet?: IDocSet;
// }

export interface IWidgetActionCancelCodeEdits extends IWdigetActionBase {
    action: "CancelCodeEdits";
}

export interface IWidgetActionSaveEdits extends IWdigetActionBase {
    action: "SaveEdits";
}

export interface IWidgetActionCancelEdits extends IWdigetActionBase {
    action: "CancelEdits";
}

export interface IWidgetActionAppendWidget extends IWdigetActionBase {
    action: "AppendWidget";
    after_contextKey?: string;       // After specified _contextKey, if none then ask parent to append after current context scope
    // _widgetType: string;          // NEw _widgetType to append
    widgetLayout: IWidgetLayout;    // New layout to use
    widgetVars?: IContextVars;       // New layout vars, if any
    autoSelect?: boolean;
}

export interface IWidgetActionDeleteWidget extends IWdigetActionBase {
    action: "DeleteWidget";
    _contextKey: _contextKey;
}

export interface IWidgetActionDoSnapshotEdits extends IWdigetActionBase {
    action: "DoSnapshot";
}

export interface IWidgetActionUndoEdits extends IWdigetActionBase {
    action: "Undo";
}

export interface IWidgetActionRedoEdits extends IWdigetActionBase {
    action: "Redo";
}

export interface IWidgetActionDebug extends IWdigetActionBase {
    action: "Debug";
}

export type WidgetAction =
    IWidgetActionAlert |
    IWidgetActionNavigate |
    // IWidgetActionNavigateToPage |
    IWidgetActionSetTopNav |
    IWidgetActionTimer |
    IWidgetActionInterval |
    //
    IWidgetActionEditWidget |
    IWidgetActionPageClick |
    IWidgetActionUpdateLayout |
    IWidgetActionBubbleChanges |
    IWidgetActionRefresh |
    IWidgetActionSetBackground |
    IWidgetActionSetOverlay |
    // IWidgetActionSetWidgetEditor |
    // IWidgetActionOpenWidgetEditor |
    // IWidgetActionCloseWidgetEditor |
    IWidgetActionOpenWidgetBrowser |
    IWidgetActionCloseWidgetBrowser |
    IWidgetActionOpenLayoutCodeEditor |
    IWidgetActionOpenWidgetCssCodeEditor |
    IWidgetActionCloseCodeEditor |
    // IWidgetActionSaveCodeEdits |
    IWidgetActionCancelCodeEdits |
    IWidgetActionSaveEdits |
    IWidgetActionCancelEdits |
    IWidgetActionAppendWidget |
    IWidgetActionDeleteWidget |
    IWidgetActionDoSnapshotEdits |
    IWidgetActionUndoEdits |
    IWidgetActionRedoEdits;
